import "./Components/LoginForm/style.css";
import {BrandImage} from "./Components/BrandImage/BrandImage";
import {LoginForm} from "./Components/LoginForm/LoginForm";


export const HomePage = () => {
    return (
        <>
            <BrandImage/>
            <LoginForm/>
        </>
    )
}